.meetingsCardContainer {
    display: flex;
    /* justify-content: space-between; */
    /* Distribute the items with space in between */
    align-items: center;
    /* Align items vertically at the center */
    margin-top: 1.5%;
    margin-left: 3%;
    margin-right: 3%;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: #FFFDFE;
    border-radius: 10px;
}

.meetingTimeColumn {
    flex-grow: 0;
    width: 12%;
    max-width: 12%;
    align-items: center;
    justify-content: center;
    margin-right: 3%;
    padding: 0 10px;
}

/* Limit the width of the meetingTitleColumn and handle text overflow */
.meetingTitleColumn {
    flex-grow: 0;
    width: 30%;
    max-width: 30%;
    margin-right: 4%;
    overflow: hidden;
    /* Ensure overflow is hidden */
    text-overflow: ellipsis;
    /* Show ellipsis when text exceeds the width */
    white-space: nowrap;
    /* Prevent text wrapping */
    align-items: center;
}

.meetingCompanyNameColumn {
    flex-grow: 0;
    width: 15%;
    max-width: 15%;
    margin-right: 3%;
    overflow: hidden;
    /* Ensure overflow is hidden */
    text-overflow: ellipsis;
    /* Show ellipsis when text exceeds the width */
    white-space: nowrap;
    /* Prevent text wrapping */
    align-items: center;
}

.meetingParticipantsNamesColumn{
    flex-grow: 0;
    width: 18%;
    max-width: 18%;
    margin-right: 3%;
    overflow: hidden;
    /* Ensure overflow is hidden */
    text-overflow: ellipsis;
    /* Show ellipsis when text exceeds the width */
    white-space: nowrap;
    /* Prevent text wrapping */
    align-items: center;
}

.meetingTimeColumn {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: #000000;

    background: #F8F2DB;
    border-radius: 10px;
    padding-top: 1%;
    padding-bottom: 1%;
}

.meetingTitleColumn,
.meetingParticipantsNamesColumn {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
}

.meetingCompanyNameColumn {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
}

.meetingActionButtons {
    flex-grow: 0;
    width: 100px;
}

.meetingActionButtons {
    /* width: 169px; */
    background-color: #F2C41E;
    border-radius: 20px;

    color: #FFFFFF;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.52px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .meetingsCardContainer {
        padding-left: 4%;
        padding-right: 4%;
    }
}

@media (max-width: 768px) {
    .meetingsCardContainer {
        flex-direction: column;
        padding: 2%;
    }
    .meetingTimeColumn,
    .meetingTitleColumn,
    .meetingCompanyNameColumn,
    .meetingParticipantsNamesColumn,
    .meetingActionButtons {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .meetingTimeColumn,
    .meetingTitleColumn,
    .meetingCompanyNameColumn,
    .meetingParticipantsNamesColumn {
        font-size: 14px;
    }
}

