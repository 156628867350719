.blogsContainer {
    width: 95%;
    margin: 0 auto;
    padding-top: 5%;
    padding-bottom: 5%;
}

.blogRow {
    display: flex;
    justify-content: flex-start;
    /* Align items to the left */
    margin-bottom: 5%;
    flex-wrap: wrap;
    /* Allow wrapping to handle dynamic content */
    /* margin-left: 22%; */
}

.blogRow:has(.blogTile:nth-child(1):last-child),
.blogRow:has(.blogTile:nth-child(2):last-child),
.blogRow:has(.blogTile:nth-child(3):last-child) {
    justify-content: flex-start;
    /* Align items to the left when fewer than 4 */
}

/* .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 10px;
} */

.pagination {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.paginationBtn {
    background-color: #d9f569;
    border-radius: 50%;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
}

.paginationBtn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pageNumber {
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
    margin: 0 5px;
    color: gray;
}

.pageNumber.active {
    font-weight: bold;
    color: black;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #F7F0D4;
}

.pageNumber:hover {
    color: black;
}

.pageNumber,
.paginationBtn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
}

.pageNumber,
.left-ellipsis,
.right-ellipsis {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.pageNumber:hover,
.left-ellipsis:hover,
.right-ellipsis:hover {
    background-color: #ffeb3b;
    border-radius: 50%;
    cursor: pointer;
}

.paginationBtn:hover {
    background-color: #FFAD0D;
}