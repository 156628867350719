body {
    margin: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

.dropdown-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}

.ant-select {
    width: 45vw; /* 20% of the viewport width */
    padding: 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #F5F5F5;
    transition: all 0.3s;

    appearance: none; /* Remove default arrow */
    -webkit-appearance: none; /* Remove default arrow in Safari */
    -moz-appearance: none; /* Remove default arrow in Firefox */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 34px 24px;
}

.ant-select:hover {
    border-color: #F5F5F5;
}

.ant-select:focus {
    border-color: #F5F5F5;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.button-container {
    margin-top: 10px; /* Add some space between the dropdown and the button */
    width: 100%; /* Ensure the button takes full width if needed */
    display: flex;
    justify-content: center; /* Center the button */
}