.remediationSidebarMilestoneSection {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    text-align: justify;
}

.remediationSidebarMilestoneTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
}

.remediationSidebarMilestoneContent > div {
    margin-top: 15px;
    display: grid;
    grid-template-columns: auto 1fr;
    /* Dynamic width for the key and flexible for value */
    gap: 10px;
}

.milestone-key-name {
    font-weight: bold;
    white-space: nowrap; /* Ensure the key text doesn't wrap */
    color: #47B881;
    font-size: 16px;
    font-weight: 700;
    margin-right: 5px;
    line-height: 19.36px
}

.milestone-key-value {
    word-wrap: break-word; /* Allow the value text to wrap within its space */
    overflow-wrap: break-word; /* Also handles long words */
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px
}

.text-strike-through-styling {
    text-decoration: line-through;
}