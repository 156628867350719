/* Chat Container */
.chat-container {
  display: flex;
  height: 100vh;
  background-color: white;
  width: 95vw;
  padding: 20px;
}

.selectProfessionalToChat {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 700;
}

/* Sidebar */
.sidebar {
  width: 100%;
  /* background-color: #f3f4f6; */
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
}

/* .search-box {
  margin-bottom: 16px;
} */

/* .search-input {
  width: 100%;
  padding: 8px;
  border-radius: 9999px;
  background-color: #e5e7eb;
  color: #4b5563;
  outline: none;
} */

.userSearchSection {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 16px;

  .userSearchBar {
    flex: 1;
    background-color: #F1F1F1;
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 20px;
    padding: 10px;

    input {
      background-color: transparent;
      border: none;
      outline: none;
      /* color: white; */
      flex: 1;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  /* .add {
      width: 36px;
      height: 36px;
      background-color: rgba(17, 25, 40, 0.5);
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
  } */
}

.chat-list {
  flex: 1;
  overflow-y: auto !important;

  .noRecentChatsAvailable {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
  }
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 18px;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 8px;
  cursor: pointer;

  .last-seen-details {
    .unread-days {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 12px;
      margin-left: 2px;
    }
  }
}

.chat-item.active {
  background-color: #F7F0D4;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* the below styling makes the avatar into a circle */
  /* border-radius: 9999px; */
  /* whereas this border stlying makes the avatar into a rounded square */
  border-radius: 10px;
  font-size: 16px;
}

.chat-info {
  flex: 1;
  margin-left: 16px;
}

.chat-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.chat-preview {
  font-weight: 500;
  font-size: 12px;
  /* color: #6b7280; */
}

.unread-count {
  font-size: 12px;
  /* color: #d97706; */
  color: white;
  font-weight: bold;
  background-color: #F2C41E;
  width: 20px;
  /* Set the width of the circle */
  height: 20px;
  /* Set the height of the circle */
  text-align: center;
  /* Centers the text horizontally */
  line-height: 20px;
  /* Centers the text vertically */
  border-radius: 50%;
  /* Makes the div a circle */
}

/* Chat Section */
.chat-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: white;
  justify-content: space-between;
  height: 100vh;
  /* flex: 3; */

  .loadingSpinnerForUserChats{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .failedToLoadConversationswithProfessional {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 24px;
    font-weight: 700;
    height: 100vh;
  }
}

.chat-messages {
  /* flex: 2; */
  /* flex-basis: 75%; */
  height: 70vh;
  overflow-y: auto;
  padding-bottom: 16px;
}

.message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}

.avatarForOutGoingMessage {
  margin-left: 10px;
  margin-right: 3%;
  /* margin-top: 10px; */
}

.avatarForOutIncomingMessage {
  margin-right: 10px;
  /* margin-top: 10px; */
}

.message.incoming {
  justify-content: flex-start;
}

.message.outgoing {
  justify-content: flex-end;
}

.message-content {
  max-width: 60%;
  background-color: #D9D9D9;
  padding: 12px;
  border-radius: 12px;
}

.message-name {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin-bottom: 4px;
}

.message-text {
  font-size: 14px;
  color: #4b5563;
}

/* Message Input */
.message-input {
  display: flex;
  align-items: center;
  background-color: #F7F0D4;
  padding: 16px;
  border-radius: 12px;
  margin-right: 28px;
  position: relative;
  top: -12%;

  input {
    flex: 1;
    background-color: #F7F0D4;
    border: none;
    outline: none;
    color: black;
    padding: 10px;
    border-radius: 2px;
    font-size: 16px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

.input-box {
  flex: 1;
  border: none;
  background-color: transparent;
  padding: 0 8px;
  outline: none;
}

.fas {
  color: #6b7280;
  font-size: 18px;
}

.bottomMessageInput {
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-top: 1px solid black; */
  gap: 20px;
  flex: 0 1 0;
  margin-top: auto;

  .userSendMessageSection {
    display: flex;
    align-items: center;
    gap: 20px;
    /* padding: 20px; */
    flex: 1;

    .messageBarSection {
      flex: 1;
      background-color: #F7F0D4;
      display: flex;
      align-items: center;
      gap: 20px;
      border-radius: 20px;
      padding: 10px;

      input {
        flex: 1;
        background-color: #F7F0D4;
        border: none;
        outline: none;
        color: black;
        padding: 10px;
        border-radius: 2px;
        font-size: 16px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}