.radio-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block-start: auto;
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center; /* Ensures text inside the radio group is centered */
    text-align: left; /* Ensures text inside the radio group is aligned left */
    width: 10vw; /* Takes up full width to ensure centering */
    max-width: 100vw; /* Optional: Limits the width of the radio group for better readability */
}

.radio-group .ant-radio-wrapper {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 50vw; /* Takes up full width to ensure centering */
    white-space: nowrap;
}

.radio-group .ant-radio-wrapper .ant-radio + span {
    margin-left: 30px; 
    /* Adjust the value as needed */
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

/* Custom styles for the radio button */
.radio-group .ant-radio-inner {
    background-color: #D9D9D9; /* Set the background color of the radio button */
}

.radio-group .ant-radio-checked .ant-radio-inner {
    background-color: #F2C41E; /* Ensure the background color stays #D9D9D9 when checked */
    border-color: #D9D9D9; /* Set the border color to #D9D9D9 */
}

/* Set the hover and focus border color to #D9D9D9 */
.radio-group .ant-radio:hover .ant-radio-inner,
.radio-group .ant-radio-input:focus + .ant-radio-inner {
    border-color: #D9D9D9; 
}