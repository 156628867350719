.meetingsListContainer {
    margin-top: 1.5%;
    background: #F5F5F5;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.listOfMeetingCards {
    max-height: 65vh;
    overflow-y: scroll;
}

.emptyCardSpace {
    margin-top: 2%;
}

.emptyTopSpace {
    margin-top: 1%;
}

.noMeetingsListView {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 10%;
    padding-bottom: 10%;
    font-size: 24px;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
}

@media (max-width: 1024px) {
    .meetingsListContainer {
        margin-left: 4%;
        margin-right: 4%;
    }
}

@media (max-width: 768px) {
    .meetingsListContainer {
        margin-left: 3%;
        margin-right: 3%;
    }
}

@media (max-width: 480px) {
    .meetingsListContainer {
        margin-left: 2%;
        margin-right: 2%;
    }
}