/* Custom Styles Written for the common button to be shown with the Yellow Background Color */ 
.continueButtonForAssessmentForm {
    color: white;
    width: 17vw;
    background-color: #F2C41E !important; /* b. No fill background color */
    border-color: #F2C41E !important; /* c. Light yellow border color */
    border-radius: 30px !important; /* a. Rounded corner edges */
    padding: 20px !important; /* Wider button with minimal height */
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;

    font-weight: bold;
    /* color: #000000d9; */
    /* text-align: center; */
    /* background-color: #fff; */
    /* border: 1px solid #d9d9d9; */
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;
    touch-action: manipulation;
    /* height: 32px; */
    font-size: 16px;
    /* border-radius: 2px; */
    margin-top: 2%;
}

.continueButtonForAssessmentForm:hover {
    color: #fff !important;
    background-color: #F2C41E !important;
    border-color: #F2C41E !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}