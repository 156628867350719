html, body {
    height: 100%;
    margin: 0;
    overflow: auto; /* Ensure that the browser handles the scrolling */
}

.completePageContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* This ensures the container takes up the full height of the viewport */
}

.mainContentBelowTheNavbar {
    flex-grow: 1; /* Allows the container to grow and fill available space */
    margin-top: 45px; /* Adjust according to the Navbar height */
    padding: 20px;
}

.blogScreenContainer {
    display: flex;
    flex-direction: column;
}
