.assessment-report-detail-tabs-container {
    width: 100%;
    border-radius: 20px;
    background-color: #F5F5F5;
    /* font-family: "Avenir Next"; */
}

.assessment-report-detail-tab.active {
    background-color: #FFFFFF;
    font-weight: 600;
    /* font-family: "Avenir Next"; */
}

.assessment-report-detail-tab {
    font-size: 16px;
    font-weight: 400;
    color: #4B4B4B;
}

.section-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 5px;
    margin-top: 30px;
    /* font-family: "Avenir Next"; */
}

.sectionTitleBottomLine {
    border-bottom: 0.5px solid #CACACA
}

.section-content {
    margin-top: 10px;
    /* font-family: "Avenir Next"; */
}

.analysisTitle {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.analysis-text,
.section-estimates {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    /* text-align: justify; */
}

.analysis-text {
    /* font-family: "Avenir Next"; */
}

.section-estimates {
    display: flex;
    /* font-family: "Avenir Next"; */
    /* justify-content: space-between; */
}

.blue-box-container {
    width: fit-content;
    background-color: #D7F5FF;
    padding: 15px;
    border-radius: 10px;
    margin-right: 2%;
}

.blue-box-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16.39px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.blue-box-description {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.green-box-container {
    width: fit-content;
    background-color: #DDFFE9;
    padding: 15px;
    border-radius: 10px;
    margin-right: 2%;
}

.green-box-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16.39px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.green-box-description {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* margin-bottom: 10px; */
    /* font-family: "Avenir Next"; */
}

.yellow-box-container {
    width: fit-content;
    background-color: #FFFBD1;
    padding: 15px;
    border-radius: 10px;
}

.yellow-box-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16.39px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.yellow-box-description {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.white-box-container {
    width: 21%;
    background-color: white;
    border: 0.5px solid #E1E1E1;
    padding: 15px;
    border-radius: 10px;
    margin-right: 2%;
}

.white-box-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16.39px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.white-box-description {
    color: #1F1F1F;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.strengthAndWeaknessTable {
    width: 100%;
    display: flex;
    /* text-align: justify; */
    margin-top: 30px;
}

.strengthTableColumn,
.weaknessTableColumnm {
    width: 50%;

}

.strengthTableColumnTitle,
.weaknessTableColumnmTitle {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 5px;
    /* margin-top: 30px; */
    margin-bottom: 10px;
}

.weaknessTableColumnmTitle {
    padding-left: 20px;
}

.strengthBorderLine,
.weaknessBorderLine {
    border-bottom: 0.5px solid #CACACA;
}

.strengthTableColumn {
    border-right: 0.5px solid #CACACA;

}

.strengths {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
}

.weakness {
    padding-left: 20px;
}

.strengths,
.weakness {
    /* font-family: "Avenir Next"; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
}

.listOfRecommendations {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* text-align: justify; */
    /* font-family: "Avenir Next"; */
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.recommendationItemTitle {
    font-size: 16px;
    /* This is an example for H4 size, adjust based on your design */
    font-weight: 600;
    /* Medium weight */
    line-height: 1.4;
    /* Optional: adjust for better spacing */
}

.recommendationConclusion {
    margin-top: 20px;
}