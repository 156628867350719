.remediation-tabs-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px; */
    box-sizing: border-box;
    height: 60px;
    width: 45%;
    background-color: #FCF6EB;
    border-radius: 10px;
}

.remediation-tabs {
    display: flex;
    /* gap: 20px; */
    /* float: left; */
    width: 100%;
    height: 100%; /* Fill the height */
}

.remediation-tab {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 10px;
    cursor: pointer;
    /* width: 18%; */
    flex: 1;
    text-align: center;
    align-self: center; /* Make sure it fills the height */
}

.remediation-tab.active {
    background-color: #FFFFFF;
    border-radius: 8px;
    /* margin-left: 1px; */
    /* padding: 10px; */
}

.remediation-tab-content {
    padding: 20px;
    font-size: 18px;
    clear: both;
    width: 100%; /* Ensure content area takes full width */
    box-sizing: border-box;
}

.remediation-tab-report-card-wrapper {
    width: 100%; /* Ensure the wrapper for the ReportCard takes full width */
    box-sizing: border-box; /* Include padding in the width calculation */
}

