/* Parent container that centers all items */
.no-mails-header-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center;  */
    /* Center items vertically */
    align-items: center; /* Center items horizontally */
    height: 100vh; /* Full height of the viewport */
    text-align: center;
    margin-top: 5%;
  }
  
  /* Styles for the image */
  .header-image {
    width: 165px;
    height: 165px;
    margin-bottom: 20px;
  }

  .no-data-image {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  /* Styles for the title */
  .header-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Styles for the subtitle */
  .header-subtitle {
    font-size: 16px;
    color: #8899A6;
    margin-bottom: 20px;
    width: 27%;
    font-weight: 400;
  }
  
  /* Styles for the button */
  .header-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #F2C41E;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .header-button:hover {
    /* background-color: yellow; */
  }
  