.remediationSidebarAttachmentSection {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}

.remediationSidebarAttachmentTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
}

.file-attachement-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    /* padding: 10px; */
    /* Add padding for better spacing */
}

.attached-file-info {
    display: flex;
    align-items: center;
}

.attached-file-icon {
    width: 41px;
    height: 41px;
    margin-right: 10px;
    display: flex;
    align-items: flex-start; /* Ensures that items start from the top */
    /* Spacing between icon and text */
}

.attached-file-text-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 41px;
    /* Align text with the height of the icon */
}

.attached-file-name {
    /* Additional styles if needed */
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    margin-bottom: 0; /* Ensures no extra space below the name */
    color: #000000;
}

.attached-file-date {
    /* Additional styles if needed */
    font-size: 11px;
    font-weight: 400;
    line-height: 13.31px;
    align-self: flex-end; /* Pushes the date to the bottom of the container */
    color: #000000;
}

.attached-file-actions {
    display: flex;
    /* Ensures that the buttons are in a row */
    align-items: center;
    /* Centers the buttons vertically */
}

.attached-file-actions button {
    background: none;
    border: none;
    margin-left: 10px;
    /* Spacing between buttons */
    /* padding: 5px 10px; */
    cursor: pointer;
    display: flex;
    align-items: center;
}

.attached-file-actions img {
    margin-right: 5px;
    /* Space between icon and text */
}

/* Styling for the buttons' hover effects */
.attached-file-actions button:hover {
    /* background-color: #f0f0f0; */
}

.file-action-span-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    vertical-align: middle; 
}

.attached-file-view-button {
    margin-right: 45px;
    color: #000000;
}

.attached-file-download-button {
    display: flex;
    align-items: center;
    justify-content: center; /* Adjust to 'flex-start' if necessary */
    text-decoration: none;
    color: black;
}

.attached-file-download-button img {
    vertical-align: middle;
    margin-right: 8px; /* Spacing between the icon and the text */
}

.attached-file-download-button:hover {
    color: #000; /* Changes the text color on hover */
}