.executableSolutionsContent {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    /* text-align: justify !important; */
    hyphens: auto !important;
    overflow-wrap: break-word; /* Move long words to the next line */
    word-break: keep-all;      /* Prevent word breaks */
}

.accelerateUserGrowthSection {
    margin-top: 30px;
}

.executable-solutions-section-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #4B4B4B;
}


.executable-solutions-section-title-bottom-line {
    margin-top: 5px;
    border-bottom: 0.5px solid #CACACA
}

.stratergiesTitleText {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24.59px;
    color: #4B4B4B;
}

.stratergySectionHeader {
    margin-top: 5px;
}

.stratergy-section-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    background-color: #FFFBD1;
    padding: 8px;
    display: flex !important;
    align-items: center !important;
}

.stratergySectionContent {
    margin-top: 15px;
}

.stratergySectionContentContainer {
    display: flex;
    align-items: flex-start;
    /* Align items to the top */
    gap: 10px;
    /* Optional: Adds space between the button and text */
    padding-left: 8px;
}

.subsequentStratergySectionContentContainer {
    margin-top: 20px;
}

.stratergySectionButtonWrapper {
    display: flex;
    justify-content: center !important;
    /* Horizontally center the button */
    align-items: center !important;
    /* Vertically center the button */
}

.stratergySectionContentRoundButton {
    width: 9px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
    /* Make it a perfect circle */
    align-items: center !important;
    justify-content: center !important;
    flex-shrink: 0;
    /* Prevents the button from shrinking */
    margin-top: 8px;
}


.stratergySectionTextContent {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.12px;
    color: #000000;
}

.executableSectionContainer {
    background: #F8FCFF;
    margin-top: 40px;
    padding-top: 30px;
    padding-left: 15px;
    border-radius: 10px;
    padding-bottom: 20px;
}

.executableSectionHeader {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #3A70E2;
}

.problemContainer,
.solutionContainer {
    background-color: #FFFFFF;
    padding: 10px;
    width: 40%;
    height: fit-content;
}

.problemSolutionContainer {
    display: flex;
    gap: 30px;
    margin-top: 30px;
}

.problemTitle,
.solutionTitle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}

.problemTitle {
    color: #F64C4C;
}

.solutionTitle {
    color: #47B881;
}

.problemDescription,
.solutionDescription {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    padding-bottom: 20px;
} 

.actionableStepsContentContainer {
    margin-bottom: 20px;
}

.actionableStepsTitleContainer {
    margin-top: 40px;
    display: flex;
    gap: 10px;
}

.actionableStepsTitleText {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1F1F1F;
}

.executableSolutionsCardContainer {
    background: #FFFFFF;
    padding: 10px;
    margin-top: 20px;
    width: 85%;
    border-radius: 10px;
}

.actionableStepTextDescriptionPoint,
.actionableStepArrowIcon {
    padding: 10px;
}

.actionableStepTextDescriptionPoint {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F; 
}