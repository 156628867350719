.table-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 50px;
  }
  
  .ranking-table {
    width: 90%;
    border-collapse: collapse;
    border: 1px solid #BDDDFF
  }
  
  .ranking-table th, .ranking-table td {
    padding: 15px 20px;
    border: 1px solid #BDDDFF;
    text-align: center;
    border-radius: 10px;
  }
  
  .ranking-table th {
    background-color: #FFFFFF;
    font-weight: 500;
    font-size: 18px;
    line-height: 24.59px;
    color: #3A70E2;
  }

  .ranking-table tbody {
    background-color: #FFFFFF;
  }
  
  .badge {
    padding: 10px 30px;
    border-radius: 12px;
  }
  
  .below-average {
    /* background-color: #f66; */
    border: 0.5px solid #F64C4C;
    background: #FFFBFB;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #F64C4C;
  }
  
  .average {
    /* background-color: #ffc107; */
    border: 0.5px solid #FE9B0E;
    background: #FFF9E8;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FE9B0E;
  }
  
  .rowAspectInTable {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    text-align: left !important;
  }

  .header-icon {
    width: 18px;  /* Adjust size as needed */
    height: 18px;
    margin-right: 8px;  /* Adds space between the image and text */
    vertical-align: middle;  /* Ensures the image is vertically aligned */
  }