.remediationSidebarCommentsSection {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    
}

.remediationSidebarCommentsTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
}

.remediationSidebarCommentsContent {
    margin-top: 10px;
    margin-bottom: 20px;
}

.remediation-comment-container {
    display: flex;
    margin-bottom: 20px;
}

.remediation-comment-avatar img {
    border-radius: 50%;
    width: 40px;
    /* Adjust size as needed */
    height: 40px;
}

.remediation-comment-content {
    margin-left: 10px;
}

.remediation-comment-user-details {
    display: flex;
    align-items: baseline;
}

.remediation-comment-user-name {
    font-weight: 300;
    font-size: 12px;
    line-height: 14.52px;
    margin-right: 5px;
    color: #000000;
}

.remediation-comment-comment-date {
    color: #000000;
    /* Lighter color for date */
    font-weight: 300;
    font-size: 12px;
    line-height: 14.52px;
}

.remediation-comment-message {
    margin-top: 5px;
    /* Space between user details and message */
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    text-align: justify;
}

.send-comment-input-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 0 10px; /* Add padding here to keep children away from the edges */
    box-sizing: border-box; /* Ensures padding and border are included in the element's width */

    input{
        flex: 1;
        flex-grow: 1; /* Ensures it takes up only available space */
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border: none;
        outline: none;
    }

    img {
        width: 20px;
        height: auto;
        cursor: pointer;
    }
}