.blogTile {
    flex: 1;
    /* margin: 0 10px; */
    width: 60%;
    max-width: 60%;
    /* Ensure four tiles fit in a row */
}

.defaultBlogTileMarginRight {
    margin-right: 5%;
}

.lastBlogTileMarginRight {
    margin-right: 0;
}

.blogImageWrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    /* Ensure the image stays within the wrapper bounds */
    position: relative;
    border-radius: 10px;
    /* Apply rounded corners */
}

.blogImage {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
    /* Smooth zoom transition */
    object-fit: cover;
    /* Ensure the image fills the wrapper without distortion */
    border-radius: 10px;
    /* Apply rounded corners */
}

.blogImageWrapper:hover .blogImage {
    transform: scale(1.1);
    /* Zoom the image */
}

.blogDate {
    margin-top: 5px;
    font-size: 10px;
    color: darkgrey;
}

.blogTitle {
    margin-top: 5px;
    padding-right: 5px;
    font-size: 18px;
    font-weight: 700;
    width: 88%;
}

.blogReadMore {
    display: inline-flex;
    align-items: center;
    /* Vertically center the text and icon */
    /* justify-content: flex-start; */
    position: relative;
    /* Use relative positioning to align icons */
    /* Ensure the text and icon are left-aligned */
    gap: 5px;
    /* Space between the text and the plus icon */
    margin-top: 10px;
    width: fit-content;
    cursor: pointer;
}

.blogReadMoreText {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    /* Remove any top margin */
    display: inline-block;
    position: relative;
    /* Ensure the text stays inline with the icons */
}

/* Set default visibility for both icons */
.plusIcon {
    opacity: 1;
}

.arrowIcon {
    opacity: 0;
    /* Position it over the plusIcon */
}

.readMorePlusIcon,
.readMoreArrowIcon {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    /* Optional: add some margin if you want more space between the text and icon */
    position: absolute;
    /* Both icons will occupy the same space */
    right: -20px;
    /* Align icons next to the text */
    transition: opacity 0.3s ease;
    /* Smooth transition */
}

.blogReadMore:hover {
    cursor: pointer;
    color: darkgrey;
    transition: 0.3s ease;

    .blogReadMoreText {
        font-size: 12px;
    }

    .plusIcon {
        opacity: 0;
    }

    .arrowIcon {
        opacity: 1;
    }

}