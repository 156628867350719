.remediationSidebarHeaderSection {
    margin: 20px;
}

.remediationSidebarHeaderTitle {
    font-size: 24px;
    font-weight: 700;
}

.remediationSidebarHeaderSubTitle {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
}

.remediationSidebarHeader-tags-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 20px;
}

.remediationSidebarHeader-tag {
    border: 1px solid;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 11px;
    font-weight: 700;
    background-color: #FFFFFF;
    color: white;
    /* font-family: "Inter"; */
    /* font-family: 'Inter', sans-serif; */
}

.firstTag {
    background-color: #EB505087;
}

.secondTag {
    background-color: #000000;
}

.thirdTag {
    background-color: #61D85E;
}

.fourthTag {
    background-color: #FDB52B;
}