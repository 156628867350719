.completePageContainerForTheMessagingPortal {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.mainContentBelowTheNavbarForTheMessagingPortal {
    margin-top: 45px;
    /* Adjust according to the Navbar height */
    /* padding: 10px; */
    /* overflow-y: auto; */
    height: calc(100vh - 45px);
    /* Adjust according to the Navbar height */
}