.reports-card-container {
    background-color: #f7f7f7;
    border-radius: 15px;
    padding: 10px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* Ensures the card takes the full width of the parent */
    box-sizing: border-box;
    /* Include padding in the width calculation */
    max-width: 99% !important;
    margin-top: 1.5%;
}

.card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.card-section {
    display: flex;
    flex-direction: column;
    /* width: 100vw; */
    width: 33%;
}

.card-section-timeline {
    width: 60%;
}

.report-title {
    font-weight: bold;
    font-size: 16px;
}

.report-date {
    color: #777777;
    font-size: 12px;
}

.report-subtitle {
    font-size: 16px !important;
    padding: 1px;
}

.report-price {
    font-weight: bold;
}

.tag {
    background-color: #f8f4d0;
    color: #000000;
    font-weight: bold;
    padding: 10px 30px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileNameAvatar {
    width: 50px;
    height: 50px;
    background-color: #f8f4d0;
    color: #4B4B4B;
    display: flex;
    align-items: center;
    justify-content: center;
    /* the below styling makes the avatar into a circle */
    /* border-radius: 9999px; */
    /* whereas this border stlying makes the avatar into a rounded square */
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
  }

.buttons {
    display: flex;
    gap: 16px;
    flex-direction: row;
    /* Ensure buttons are in a row */
    align-items: center;
    /* Center align the buttons vertically */
    justify-content: right;
}

.link-button,
.preparing-report {
    background: none;
    border: none;
    color: black;
    font-weight: bold;
    cursor: pointer;
    padding: 0;
    text-decoration: none;
    position: relative;
    /* Required for the after pseudo-element */
}

.link-button:hover,
.preparing-report:hover {
    /* text-decoration: underline; */
    text-decoration: none;
    /* Prevent underline on hover */
}

.link-button:after,
.preparing-report:after {
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    /* Dynamic height relative to font size */
    background-color: #F2C41E;
    /* Yellow color */
    width: 0;
    /* Initially set to zero width */
    left: 0;
    bottom: -6px;
    /* Position it at the bottom of the button */
    /* Smooth transition for the line */
    /* transition: width 0.3s ease-in-out;  */
}

.preparing-report:after {
    background-color: #4B4B4B
}

.link-button:hover:after,
.preparing-report:hover:after {
    width: 100%;
    /* Expand the line to the full width on hover */
}

/* .link-button:hover:after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    background-color: #d4a426;
    width: 100%;
    left: 0;
    bottom: -4px;
  } */

.profile-section {
    margin-right: 20px;
    /* Add space between the avatar and the card content */
}


.profile-avatar {
    width: 50px !important;
    /* Adjust size as needed */
    height: 50px !important;
    /* Adjust size as needed */
    border-radius: 50% !important;
    /* Makes the image a circle */
    background-color: #ddd !important;
    /* Placeholder background if no image */
    object-fit: cover;
    /* Ensures the image covers the entire circle */
}

.book-a-meeting-recommendations {
    background-color: white;
    /* Background color of the button */
    color: #4B4B4B;
    /* Text color (yellow) */
    border: 1px solid #F2C41E;
    /* Border color matching the text */
    border-radius: 8px;
    /* Rounded corners */
    padding: 10px 20px;
    /* Padding inside the button */
    font-size: 16px;
    /* Font size */
    font-weight: bold;
    /* Bold text */
    cursor: pointer;
    /* Pointer cursor on hover */
    text-align: center;
    /* Center the text */
    transition: background-color 0.3s ease;
    /* Smooth transition for hover effect */
}

.book-a-meeting-recommendations:hover {
    border: 1px solid #ffeb3b;
    background-color: #ffeb3b;
    /* Change background on hover */
    color: white;
    /* Change text color on hover */
}

.remediationViewDetailsButton {
    /* background: none; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: 1px solid #F2C41E;
    color: #4B4B4B;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    text-decoration: none;
    border-radius: 10px;
    position: relative;
}