@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@font-face {
  font-family: 'Avenir';
  src: url('../src/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('../src/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body{min-height: 100vh !important; }

body, p, h1, h2, h3, h4, h5, h6, li, a, span, button, b, html, .card-title, .report-title, .tag, .meetingCompanyNameColumn, .ant-steps-item-title, div{
  font-family: 'Avenir', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container{width: 100% !important; margin: auto !important;}

.bg-white{background-color: white}

.ant-btn-primary, .ant-btn-primary:hover{
  background-color: #F2C425 !important;
  color: #4b4b4b;
}

.btn{
  min-width: 80px !important;
}

.btn-white{
  background-color: #FFF !important;
  border: 1px solid #FFF !important;
  color: black;
  min-width: 200px !important;
}

.btn-white:hover{
  background-color: #F2C425 !important;
  border: 1px solid #F2C425 !important;
  color: #4b4b4b;
}

.btn-primary, .btn-primary:hover{
  background-color: #F2C425 !important;
  border: 2px solid #4b4b4b !important;
  color: #4b4b4b;
}

.btn-primary-outline{
  background-color: transparent;
  border: 1px solid #F2C425 !important;
  color: #F2C425;
}

.btn-primary-outline:hover{
  background-color: #F2C425;
  border: 1px solid #F2C425 !important;
  color: #4b4b4b;
}

.btn-primary-disabled{
  background-color: #c1c1c1 !important;
  border: 1px solid #ccc !important;
  color: #FFF !important;
  font-weight: bold;
}

.btn-dark{
  background-color: #373333 !important;
  border: 1px solid #373333 !important;
  color: #F2F2F2;
}

.btn-small{font-size: 12px; font-weight: bold}

.card-radius{border-radius: 10px}

.initials-avatar{
  width: 40px !important;
  height: 40px !important;
  background-color: #F2C425 !important;
  font-weight: bold;
}
.pricing-card{min-height: 55vh !important;}
.pricing-header{
  font-weight: bolder;
  font-size: 1.6rem;
}
.pricing-header-sub{font-size: 12px}
.pricing-list{font-size: 13px}
.pricing-detail{min-height: 20vh}

input[type="checkbox"], .ant-checkbox-wrapper {
  padding: 10px !important;
  font-size: 1rem !important;
}

/*==== Recommendation Styles ======*/
.bg-light-blue{background-color: #f0ffff}
.max-height{min-height: 80vh}
.badge-success{color: #343e3f; background-color: #caebf1; padding: 0; font-size: 11px}

.dashboard-card{
  min-height: 38vh;
  border-radius: 15px;
}

.dashboard-card-text{min-height: 18vh}

.bg-profile-gradient{
  height: 20px;
  background-color: #00b0e8;
}

.nav-item .active{
  color: #F2C425 !important;
  font-weight: bolder;
}

.profile-sections-box-detail{
  min-height:10vh;
}

.profile-avatar-box{
  width: 150px !important;
  height: 150px !important;
  background-color: #00b0e8;
  border-radius: 50%;
  text-align: center;
}

.icon-large{font-size: 2rem}

.center-text-div {
  text-align: center;
  padding-top: 20%;
}

.file-grey-box{
  background-color: #FAFAFA;
  border-radius: 20px;
}

.dot-small-card{
  height: 25px;
  width: 25px;
  background-color: #4b4b4b;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
}

.number-circle-active{
  height: 25px;
  width: 25px;
  background-color: #6bc497;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  padding-top: 3px;
}

.number-circle{
  height: 25px;
  width: 25px;
  /*background-color: #6bc497;*/
  border-radius: 50%;
  color: black;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  padding-top: 3px;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-otp{width: 100%}

.ant-select{padding: 0 !important;;background-color: transparent !important;border: none !important;width: 100% !important;}
.react-tel-input > .form-control{width: 100% !important;}


@media print {
  .no-print { display: none !important; }  /* Hide buttons during PDF generation */
}

@media only screen and (max-width: 600px) {
  .max-height{min-height: auto}
  .mobile-full-width{width: 100% !important;}
  .mobile-padding-top{padding-top: 8% !important;}
}