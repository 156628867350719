.subscriptionPlansContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;

    /* height: 100vh; */
    /* Optional: if you want it vertically centered as well */
}

.affordableSubscriptionPricingPlan,
.premiumSubscriptionPricingPlan {
    margin: 0 20px;
    /* Space between the two plans */
}

.pricingPlansHeader {
    font-size: 36px;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    color: #4b4b4b;
    display: inline-block;
    /* Makes the border take the width of the text */
    position: relative;
    padding-bottom: 10px;
    /* Adds space between text and border */
    border-bottom: 2px solid black;
    /* Adds the border */
}

.subscriptionPlansForVentures {
    margin-top: 2%;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    color: #4b4b4b;
}

.choosePlanText {
    margin-top: 1%;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    color: #4b4b4b;
    margin-bottom: 3%;
}

.subscriptionPricingIcon {
    position: relative;
    width: 20px;
    /* Resized width */
    height: 20px;
    /* Resized height */
    border-radius: 50%;
    /* Creates a circle */
    background: transparent;
    /* Transparent background */
    border: 2px solid #4caf50;
    /* Circle border color */
    display: inline-block;
    box-sizing: border-box;
}

.subscriptionPricingIcon::after {
    content: "";
    position: absolute;
    width: 5px;
    /* Checkmark width resized */
    height: 12px;
    /* Checkmark height resized */
    border: solid #4caf50;
    border-width: 0 2px 2px 0;
    /* Defines the checkmark style, adjusted for smaller size */
    transform: rotate(45deg);
    /* Rotates the border to form a checkmark */
    top: 1px;
    /* Moves checkmark down within the smaller circle */
    left: 8px;
    /* Moves checkmark horizontally to the right */
    z-index: 2;
    /* Ensures checkmark is on top */
}

/* Creating the small gap in the top-right part of the circle */
.subscriptionPricingIcon::before {
    content: "";
    position: absolute;
    top: -2px;
    /* Slightly outside the circle */
    right: -2px;
    /* Slightly outside the circle */
    width: 5px;
    /* Resized width of the gap */
    height: 5px;
    /* Resized height of the gap */
    background-color: white;
    /* To hide part of the circle */
    z-index: 1;
    /* Ensures it stays on top of the border */
    border-radius: 50%;
    /* Rounds the small gap */
}

.subscriptionPricingPlanNameWithIcon {
    display: flex;
    justify-content: center;
    /* Center the items inside this div horizontally */
    align-items: center;
    text-align: center;
}

.subscriptionPricingBenefit {
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    color: #4b4b4b;
    margin-left: 10px;
}

.subscriptionPricingPlanIcon {
    width: 16px;
    height: 16px;
}

.subscriptionPricingPlanName {
    margin-top: 5%;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    color: #4b4b4b;
}

.pricingCategories {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    width: 55%;
    /* Set the width to 40% of its container */
    margin: 0 auto;
    align-items: center;
    /* Center the block horizontally */
    padding-left: 6%;
}

.subscriptionPricingPlanCardContainer {
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    height: 62vh;
    position: relative; 
}

.subscriptionPricingPlanDescription {
    margin-top: 3%;
    font-size: 14px;
    font-weight: 600;
    color: darkgrey;
    text-align: justify;
    line-height: 1.6; /* This is a typical value, but you can adjust it based on your needs */
}

.subscriptionPricingPlanPriceValue {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 5%;
}

.subscriptionPricingPlanPriceValuePerMonthOrYearText {
    font-size: 12px;
    line-height: 12px;
    color: darkgrey;
    margin-top: 4%;
}

.additionalMarginTopForYearlyCost {
    margin-top: 10%;
}

.subscriptionPricingPlanBenefitsList {
    margin-top: 10%;
    align-items: center;
}

.subscriptionPricingPlanBenefitItem {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    align-items: center; /* Aligns items vertically in the center */
    gap: 5px; /* Space between the blue box and the text */
}

.subscriptionPricingPlanBenefitItemBlueBox {
    width: 18px;
    /* Adjust size as needed */
    height: 18px;
    /* Adjust size as needed */
    background-color: #007BFF;
    /* The blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    /* Rounded corners */
}

.subscriptionPricingPlanBenefitItemBlueBoxCheckmark {
    color: white;
    font-size: 10px;
    /* Adjust size as needed */
}

.subscriptionPricingPlanBenefitItemName {
    font-size: 14px; /* Adjust font size as needed */
    color: #333; /* Dark gray text color */
    font-weight: 500;
}

.subscribeToAPlanButton {
    position: absolute; /* Position the button absolutely within its container */
    bottom: 20px; /* 20px from the bottom */
    margin-top: 10%;
    background-color: #F2C41E;
    border-radius: 5px;
    border: 3px solid black;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    width: 83%;
    cursor: pointer;
}

.additionalMarginTopForBenefitItem {
    margin-top: 2%;
}

/* .premiumSubscriptionPricingPlan {
    position: relative;
    margin-top: -3%;
} */