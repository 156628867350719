.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    /* height: 100vh; */
    /* Full viewport height */
}

.spinner {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
}

.spinner::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: #7dcea0;
    /* Solid green line */
    animation: spin-top-bottom 1s linear infinite;
}

.loading-screen-text {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    margin-top: 3rem;
    margin-left: 2rem;
}

.postAPIHitText {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
}

.successText {
    color: green;
}

.failedText {
    color: red;
}


@keyframes spin-top-bottom {

    0%,
    25% {
        border-style: solid;
        transform: rotate(0deg);
    }

    50% {
        border-style: dotted;
        transform: rotate(180deg);
    }

    75%,
    100% {
        border-style: solid;
        transform: rotate(360deg);
    }
}