.circular-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circular-progress {
    --size: 150px;
    --stroke-width: 20px;
    --circle-color: #f66;
    --background-color: #F2F2F2;
    --percentage: 75;
    --stroke-color-start: #EB3349;
    --stroke-color-end: #FF9381;

    width: var(--size);
    height: var(--size);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-progress::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(var(--stroke-color-start) 0%,
            var(--stroke-color-end) calc(var(--percentage) * 1%),
            var(--background-color) 0);
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--stroke-width)), black calc(100% - var(--stroke-width)));
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--stroke-width)), black calc(100% - var(--stroke-width)));
}

.progress-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1F1F1F;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.progress-title {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #4B4B4B;
}

.salesCycleTitleInFinancialMetricsForBenchmark {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    margin-top: 20px;
    margin-bottom: 20px;
}

.monthlyRevenueContainerInFinancialMetricsForBenchmarks {
    background-color: #F7F9FB;
    margin-top: 20px;
    width: 75%;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 20px;
}

.monthlyRevenueTitleWithinTheContainerForFinancialMetricsForBenchmarks {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.monthlyRevenueValueWithinTheContainerForFinancialMetricsForBenchmarks {
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #4B4B4B;
}

.breakEvenTimlineForFinancialMetricsForBenchmarks {
    margin-top: 30px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    width: 90%;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 3px 0px #0000000F;
    justify-content: space-between; /* Pushes child elements to the left and right */
}

.breakEvenTimlineForFinancialMetricsForBenchmarksTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #4B4B4B;
}

.breakEvenTimlineForFinancialMetricsForBenchmarksValue {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #4B4B4B;
    margin-top: 5px;
}

.breakEvenTimlineForFinancialMetricsForBenchmarkVisualization {
    padding-right: 15px;
    align-self: center;
    vertical-align: middle;
}

.breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainerOuterCircle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #2948FF;
    /* Outer border gradient */
    display: flex;
    justify-content: center;
    align-items: center;
}

.breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainerInnerCircle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    /* Inner circle background (white) */
}
