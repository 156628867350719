.remediationSidebarDescriptionSection {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}

.remediationSidebarDescriptionTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
}

.remediationSidebarDescriptionContent {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
}
