.meeting-modal-container {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 20px;
}

.complete-meeting-modal-container {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.meetingEditButton {
    display: flex;
    align-self: end;
    background-color: #F2C41E;
    width: 170px;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 20px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    cursor: pointer;
}

.meetingTitleLabel {
    color: #000000;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
    margin-bottom: 10px;
}

.meetingTitleInputElement {
    background-color: #F1F1F1;
    border-radius: 5px;
    width: 428px;
    height: 25px;
    padding: 5px 10px;
    border: none;
}

.inviteParticipantsLabel {
    margin-top: 4%;
    color: #000000;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
    margin-bottom: 10px;
}

.input-text-area-for-list-of-emails {
    width: 613px;
    border-radius: 5px;
    background-color: #F1F1F1;
}

.listOfOtherMeetingDetails {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 4%;
    gap: 10%;
}

.listOfOtherMeetingDetailsTextInput {
    width: 227px;
    border-radius: 5px;
    background-color: #F1F1F1;
    height: 25px;
    padding: 5px 10px;
    border: none;
}

.dateOfMeetingLabel,
.meetingStartTimeLabel,
.meetingStopTimeLabel {
    color: #000000;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
    margin-bottom: 5px;
}

.meetingDescriptionLabel {
    margin-top: 4%;
    color: #000000;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
    margin-bottom: 10px;
}

.input-text-area-for-meeting-description {
    /* width: 783px; */
    border-radius: 5px;
    background-color: #F1F1F1;
}

.meetingModalEditViewActionButtons {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.meetingSaveButton {
    display: flex;
    background-color: #F2C41E;
    width: 170px;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 20px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    cursor: pointer;
}

.meetingCancelButton {
    display: flex;
    background-color: #FAFAFB;
    width: 170px;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #000000;
    border-radius: 20px;
    color: #9E99A6;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    cursor: pointer;
}

.meetingTitleValueInViewMode,
.listOfEmailsValueInViewMode,
.listOfOtherMeetingDetailsValueInViewMode,
.meetingDescriptionInputValueInViewMode {
    display: flex;
    color: #000000;
    font-weight: 400;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
}

.listOfOtherMeetingDetailsValueInViewMode {
    width: 145px;
}

.meetingDescriptionInputValueInViewMode {
    text-align: justify;
}

.searchContainerForProfessionalsContainer {
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    padding: 8px;
    border-radius: 5px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    transition: all 0.3s ease;
    width: 613px;
}

.professionals-search-input {
    border: none;
    outline: none;
    padding: 5px;
    padding-left: 25px;
    font-size: 16px;
    border-radius: 5px;
    width: 359px;
    /* height: 48px; */
    transition: width 0.3s ease;
    background-color: #F5F5F5;
}

/* Styling the placeholder text */
.professionals-search-input::placeholder {
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
}

.added-professional-names-container {
    display: 'flex';
    margin-top: 10px;
    align-items: center;
}

.added-professional-names-wrapper {
    display: 'flex';
    gap: '8px';
    flex-wrap: 'wrap';
}

.added-professional-tag {
    display: inline-flex;
    align-items: center;
    width: fit-content;
    position: relative;
    /* Make it the positioning reference for the close button */
    background-color: #f1f1f1;
    padding: 5px 20px;
    border-radius: 5px;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 14px;
    color: #333;
    /* Add extra space on the right for the close button */
}

/* Styling for the close (×) button */
.added-professional-tag-close-btn {
    position: absolute;
    top: -3px;
    right: -3px;

    background: #ff4d4d;
    border: none;
    color: white;
    font-size: 8px;
    margin-left: 8px;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    width: 12px;
    height: 12px;
    border-radius: 8px;
}

.added-professional-tag-close-btn:hover {
    color: white;
}

.delete-modal-container {
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.delete-modal-container-text {
    margin-bottom: 5%;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
}