/*!* Ant Design Checkbox Styles *!*/
/*.ant-checkbox-wrapper {*/
/*    !* display: inline-block; *!*/
/*    margin: 0 auto;*/
/*    font-size: 16px;*/
/*    line-height: 1.5;*/
/*    cursor: pointer;*/
/*    font-family: 'AvenirNextLTPro', sans-serif !important;*/
/*    font-weight: 500;*/
/*    width: 10vw; !* Takes up full width to ensure centering *!*/
/*    max-width: 100vw; !* Optional: Limits the width of the radio group for better readability *!*/
/*    white-space: nowrap;*/
/*}*/

/*.ant-checkbox {*/
/*    top: 0;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    line-height: 1;*/
/*    white-space: nowrap;*/
/*    vertical-align: middle;*/
/*    cursor: pointer;*/
/*    -webkit-transition: all 0.3s;*/
/*    transition: all 0.3s;*/
/*}*/

/*.ant-checkbox-inner {*/
/*    position: relative;*/
/*    top: 0;*/
/*    left: 0;*/
/*    display: block;*/
/*    width: 20px; !* Increased width *!*/
/*    height: 20px; !* Increased height *!*/
/*    direction: ltr;*/
/*    border: 1px solid grey;*/
/*    -webkit-transition: all 0.3s;*/
/*    transition: all 0.3s;*/
/*}*/

/*.ant-checkbox-checked .ant-checkbox-inner {*/
/*    background-color: #F2C41E !important;*/
/*    border-color:#D9D9D9;*/
/*}*/

/*.ant-checkbox-checked .ant-checkbox-inner:after {*/
/*    position: absolute;*/
/*    display: table;*/
/*    border: 2px solid #fff;*/
/*    border-top: 0;*/
/*    border-left: 0;*/
/*    content: ' ';*/
/*    height: 6px;*/
/*    left: 4px;*/
/*    top: 2px;*/
/*    width: 3px;*/
/*    -webkit-transform: rotate(45deg) scale(1);*/
/*    transform: rotate(45deg) scale(1);*/
/*    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.29, 1.46) 0.1s;*/
/*    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.29, 1.46) 0.1s;*/
/*}*/

/*.ant-checkbox-wrapper+.ant-checkbox-wrapper {*/
/*    margin-left: 8px;*/
/*}*/

/*.ant-checkbox-checked::after {*/
/*    position: absolute;*/
/*    display: block;*/
/*    content: "";*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border: 1px solid #1890ff;*/
/*    !* border-radius: 2px; *!*/
/*    visibility: hidden;*/
/*    animation: antCheckboxEffect 0.36s ease-in-out;*/
/*    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;*/
/*}*/

/*@keyframes antCheckboxEffect {*/
/*    0% {*/
/*        transform: scale(1);*/
/*        opacity: 1;*/
/*    }*/

/*    100% {*/
/*        transform: scale(1.6);*/
/*        opacity: 0;*/
/*    }*/
/*}*/

/*@-webkit-keyframes antCheckboxEffect {*/
/*    0% {*/
/*        -webkit-transform: scale(1);*/
/*        transform: scale(1);*/
/*        opacity: 1;*/
/*    }*/

/*    100% {*/
/*        -webkit-transform: scale(1.6);*/
/*        transform: scale(1.6);*/
/*        opacity: 0;*/
/*    }*/
/*}*/


/*!* Custom Styles *!*/
/*.container {*/
/*    !*padding: 20px;*!*/
/*}*/

/*.checkbox-wrapper {*/
/*    display: flex !important;*/
/*    align-items: center;*/
/*    !* margin-bottom: 16px; *!*/
/*    flex-direction: column;*/
/*}*/

/*.checkbox-wrapper label {*/
/*    margin-left: 8px;*/
/*    font-size: 14px;*/
/*}*/

/*.checkbox-wrapper .ant-checkbox + span {*/
/*    margin-left: 25px; !* Add space between the checkbox and the label *!*/
/*}*/

/*.ant-checkbox-inner {*/
/*    width: 20px !important; !* Increased width *!*/
/*    height: 20px !important; !* Increased height *!*/
/*    background-color: #D9D9D9 !important;*/
/*    !* border-radius: 5px !important;  *!*/
/*    !* Custom border radius *!*/
/*}*/

/*.ant-checkbox-checked .ant-checkbox-inner::after {*/
/*    left: 6px !important; !* Adjusted left position *!*/
/*    top: 2px !important; !* Adjusted top position *!*/
/*    width: 6px !important; !* Adjusted width *!*/
/*    height: 10px !important;  !* Adjusted height *!*/
/*    border: solid white !important;*/
/*    border-width: 0 2px 2px 0 !important;*/
/*    transform: rotate(45deg) !important;*/
/*}*/