.welcome-message-container {
    display: flex;
    flex-direction: column;
    background-color: #FCF6EB;
    padding: 3%;
    border-radius: 10px;
    width: 100%;
    position: relative;
  }
  
  .welcome-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
  }
  
  .welcome-subtitle {
    font-size: 1rem;
    color: #333;
  }
  