.completePageContainerForTheAssessmentReportView {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Avenir', Arial, sans-serif;
}

.mainContentBelowTheNavbarForTheAssessmentReportView {
    margin-top: 75px;
    /* Adjust according to the Navbar height */
    /* padding: 10px; */
    /* overflow-y: auto; */
    height: calc(100vh - 75px);
    /* Adjust according to the Navbar height */
    display: flex;
    font-family: 'Avenir', Arial, sans-serif;
}

.assessmentReportDetailContainer {
    /* margin-top: 30px; */
    width: 65%;
    margin-left: 5%;
    /* margin-right: 5%; */
    padding: 20px;
    /* background-color: #f0f0f0; */
    /* border-right: 2px solid #ccc; */
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    font-family: 'Avenir', Arial, sans-serif;
}


.assessmentReportActionContainer {
    width: 30%;
    padding: 20px;
    /* margin-top: 30px; */
    font-family: 'Avenir', Arial, sans-serif;
}