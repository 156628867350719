.remediationSidebarActionButtonsSection {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
}

.acceptByButton {
    border-radius: 10px;
    border: none;
    background-color: #47B881;
    color: white;
    font-weight: 700;
    font-size: 18px;
    line-height: 21.78px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.acceptByButton:hover {
    background-color: #47B881;
}

.accepted-timeline-action-buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal-width columns */
    gap: 30px;
    /* Optional: space between buttons */
}

.approveByButton {
    border-radius: 10px;
    border: none;
    background-color: #47B881;
    color: white;
    font-weight: 700;
    font-size: 18px;
    line-height: 21.78px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.lay-a-complain-button {
    border-radius: 10px;
    border: none;
    background-color: #E02F2F;
    color: white;
    font-weight: 700;
    font-size: 18px;
    line-height: 21.78px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}