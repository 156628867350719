.generateADocumentButton {
    display: flex;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    background-color: #4B4B4B;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
    cursor: pointer;
    /* font-family: "Avenir Next"; */
}

.hiddenButton {
    margin-bottom: 4.3rem;
}

.generateADocumentButton:hover {
    background-color: #FFD152 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: white !important;
    cursor: pointer;
}

.listOfAssessmentReportSections {
    position: fixed;
    background-color: #F7F9FB;
    /*width: 70%;*/
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    gap: 10px;
    cursor: pointer;
    padding: 2%;
}

.inActiveNavigationItem {
    /* font-family: "Avenir Next"; */
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    color: #1F1F1F;
    padding-left: 0px;
    transition: all 0.3s ease;
    /* Smooth transition for color and border changes */
}

.activeNavigationItem {
    /* font-family: "Avenir Next"; */
    font-size: 12px;
    font-weight: 500;
    line-height: 16.39px;
    color: #3B82F6;
    border-left: 2px solid #3B82F6;
    position: relative;
    padding-left: 8px;
}

.actionsContainer {
    font-family: 'AvenirNextLTPro', sans-serif !important;
}