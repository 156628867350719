.benchmarkContent {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    /* text-align: justify !important; */
    hyphens: auto !important;
    /* overflow-wrap: break-word;  */
    /* Move long words to the next line */
    word-break: keep-all;      
    /* Prevent word breaks */
}

.benchmarkSectionContainer {
    background: #F8FCFF;
    margin-top: 40px;
    padding-top: 30px;
    padding-left: 25px;
    border-radius: 10px;
    padding-bottom: 20px;
}

.sectionTitleAndBenchmarkEvalution {
    display: flex;
    justify-content: space-between;
    /* Align items to left and right */
    align-self: center;
    /* Vertically align the elements */
}

.benchmarkSectionHeader {
    font-weight: 700;
    font-size: 24px;
    color: #3A70E2;
}

.benchmarkEvaluationRanking {
    display: flex;
    /* Align icon and text horizontally */
    align-self: center;
    width: 22%;
    margin-right: 25px;
    background-color: #FFFBFB;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
}

.rakingTitleAndDescription {
    padding-left: 5px;
}

.rankingTitleName {
    align-self: flex-start;
    /* Forces it to align left within a flex container */
    text-align: left !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    position: relative;
    margin-left: 0px;
}

.belowAvergaeRanking {
    border: 0.5px solid #F64C4C
}

.avergaeRanking {
    border: 0.5px solid #FFAD0D;
}

.aboveAverageRanking {
    border: 0.5px solid #47B881;
}

.rankingDescription {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.belowAvergaeRankingDescription {
    color: #F64C4C;
}

.avergaeRankingDescription {
    color: #FFAD0D;
}

.aboveAverageRankingDescription {
    color: #47B881;
}

.defaultTextColor {
    color: #4B4B4B;
}

.tamAndSamHeader {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #4B4B4B;
}

.tamAndSamContentContainer,
.marketOpportunityGraphContainers {
    display: flex;
    gap: 30px;
    margin-top: 30px;
}

.anvlistContainer,
.industryStandardContainer {
    background-color: #FFFFFF;
    padding: 10px;
    width: 40%;
    height: fit-content;
}

.anvilistTitle,
.industryStandardTitle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.tamContent,
.samContent {
    display: flex;
}

.tamContentKey,
.samContentKey {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.tamContentDescription,
.samContentDescription {
    margin-left: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.totalAddressableMarketGraphContainer,
.servicableAddresableMarketGraphContainer {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 20px 0px #CFCFCF2B;
    padding: 10px;
    width: 45%;
    height: fit-content;
    position: relative;
    /* Important for percentage height */
    border-radius: 10px;
}

.totalAddressableMarketGraphContainerTitle,
.servicableAddresableMarketGraphContainerTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding: 10px;
}

.legendForTamAndSamContent {
    padding-left: 25px;
}

.industryStandardLegend,
.companyLegend {
    display: flex;
    align-self: center;
    gap: 10px;
    margin-bottom: 10px;
}

.colorBar {
    width: 25px;
    height: 1px;
    border-radius: 10px;
    padding: 2px;
    align-self: center;
    position: relative;
    margin-top: 3px;
}

.industryStandardColor {
    background-color: #FDB52B;
}

.companyColor {
    background-color: #47B881;
}

.industryStandardColorTAM {
    background-color: #00B0E8;
}

.companyColorTAM {
    background-color: #F66CD7;
}

.legendText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.rankingInformation {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.howToGetThereContentContainer {
    margin-bottom: 20px;
}

.howToGetThereHeader {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1F1F1F;
    margin-top: 30px;
}

.howtoGetThereRecommendationsCardContainer {
    background: #FFFFFF;
    padding: 10px;
    margin-top: 20px;
    width: 85%;
    border-radius: 10px;
}

.howtoGetThereRecommendationsTextDescriptionPoint,
.howtoGetThereRecommendationsStepArrowIcon {
    padding: 10px;
}

.howToGetThereRecommendationPointIndex {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-left: 10px;
}

.howtoGetThereRecommendationsTextDescriptionPoint {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.anvlistAndindustryStandardContentContainer {
    display: flex;
    gap: 30px;
    margin-top: 30px;
}

.competitorsDescriptionContent,
.differentiatorsDescriptionContent {
    display: block;
    /* Make the container block-level */
    width: 100%;
    /* Let the container take full width */
    margin-top: 20px;
}

.competitorsContentKey,
.differentiatorsContentKey {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;

    display: inline-block;
    /* Allow the label to stay inline with the content */
    white-space: nowrap;
    /* Prevent the label from wrapping */
    margin-right: 5px;
    /* Add a small gap between label and content */
}

.differentiatorsContentDescription {
    margin-left: 1px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    display: inline;
    /* Keep the value inline with the label */
    word-break: break-word;
    /* Break the text only if it's too long */
}

.competitorsContentDescription {
    margin-left: 1px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    display: inline;
    /* Keep the value inline with the label */
    word-break: break-word;
    /* Break the text only if it's too long */
}