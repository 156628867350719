.footer-container {
    background-color: #FFC936; /* Yellow background */
    padding: 50px;
    border-radius: 20px; /* Rounded corners */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    /* margin-left: 15%; */
    width: 80%;
    margin: 0 auto;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer-left p {
    margin: 0;
    font-size: 16px;
    color: #333;
    text-align: left;
    font-weight: bold;
    padding: 10px;
}

.footer-right {
    display: flex;
    gap: 15px;
}

.social-icon {
    width: 24px;  /* Adjust size of the social icons */
    height: 24px; /* Ensure consistent sizing */
    cursor: pointer;
}

.social-icon:hover {
    opacity: 0.7; /* Simple hover effect */
}
