.meetingsContainerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.meetingsBackButtonImage {
    width: 18px;
    height: 18px;
}

.backAndSearchContainer {
    display: flex;
    gap: 20px;
}

.meetingsBackButtonText {
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 24.2px;
}

.backButtonForMeetingsContainer {
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
}

.searchContainerForMeetingsContainer {
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    padding: 8px;
    border-radius: 15px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    transition: all 0.3s ease;
}

/* Styling for the search input */
.meetings-container-search-input {
    border: none;
    outline: none;
    padding: 5px;
    padding-left: 25px;
    font-size: 16px;
    border-radius: 15px;
    width: 359px;
    /* height: 48px; */
    transition: width 0.3s ease;
    background-color: #F5F5F5;
}

/* Responsive input expansion on focus */
/* .meetings-container-search-input:focus {
    width: 300px;
} */

/* Hover effect for container */
/* .searchContainerForMeetingsContainer:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
} */

/* Styling the placeholder text */
.meetings-container-search-input::placeholder {
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
}

.newMeetingsButton {
    width: 169px;
    background-color: #F2C41E;
    border-radius: 20px;

    color: #FFFFFF;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .meetingsContainerHeader {
        flex-direction: column;
        align-items: center;
        margin: 3% 3%;
    }

    .newMeetingsButton,
    .backAndSearchContainer {
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .meetingsContainerHeader {
        margin: 2% 2%;
    }

    .meetingsBackButtonText,
    .meetings-container-search-input {
        font-size: 14px;
    }
}