.section-info {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.section-name {
    float: left;
}

.section-progress {
    float: right;
}