.tabs-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
    height: 4%;
}

.tabs {
    display: flex;
    gap: 20px;
    float: left;
    width: 82%;
}

.tab {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
    min-width: 25%;
    /* flex: 1; */
    text-align: center;
}

.tab.active {
    background-color: #F1F1F1;
    border-radius: 8px;
}

.notification-badge {
    margin-left: 8px;
    background-color: red;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    border-radius: 50%;
    padding: 2px 6px;
    display: inline-block;
    /* position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    padding: 2px 6px; */
}

.button-container {
    width: 18%;
    float: right;
}

.assessment-button {
    background-color: #F2C41E;
    color: #000;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    margin-left: 0.8rem;
}

.assessment-button:hover {
    background-color: #ffeb3b;
}

.content {
    padding: 20px;
    font-size: 18px;
    clear: both;
    width: 100%; /* Ensure content area takes full width */
    box-sizing: border-box;
}

.report-card-wrapper {
    width: 100%; /* Ensure the wrapper for the ReportCard takes full width */
    box-sizing: border-box; /* Include padding in the width calculation */
}

.recommendation-search-bar {
    width: 98%;
    margin-left: 1%;
    border-radius: 15px;
    border: none;
}

.viewAllTimelines {
    float: right;
    margin-right: 1.5%;
    font-weight: 700;
    margin-top: 2%;
    margin-bottom: 1%;
    cursor: pointer;
}

.spinner-container {
    display: flex;
    align-items: center;   /* This aligns the spinner vertically */
    justify-content: center; /* This aligns the spinner horizontally */
    height: 50vh; /* You can adjust this height as necessary */
    margin: 0 auto;
}