/* imported default Ant Design button styles from the online documentation */
.ant-btn {
    display: inline-block;
    font-weight: 400;
    color: #000000d9;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;
    touch-action: manipulation;
    height: 30px;
    padding: 4px 15px;
    font-size: 16px;
    border-radius: 2px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.save-exit-btn {
    /* color: black; */
    position: absolute;
    top: 5%;
    right: 20px;
    font-size: 1em;
    /* padding: 10px 20px; */
    /* line-height: 1.5;  */
    /* Ensure text is centered vertically */
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 35%;
    /*width: 11%;*/
    transition: box-shadow 0.3s ease;
    font-weight: bold;
}

.save-exit-btn:hover {
    color: #fff !important;
    background-color: #F2C41E !important;
    border-color: #F2C41E !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}

.previousButton {
    display: inline-block;
    font-weight: 400;
    color: #000000d9;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    /* padding: 4px 15px; */
    font-size: 16px;

    position: absolute;
    right: 20px;
    font-size: 1em;
    padding: 25px 20px;
    /* line-height: 1.5;  */
    /* Ensure text is centered vertically */
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    width: 11%;
    transition: box-shadow 0.3s ease;
    font-weight: bold;

    margin-left: 20px;
    margin-right: 2rem;
}