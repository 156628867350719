.blogScreenHeader {
    /* padding: 40px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background: linear-gradient(to right, #fdd5e5, #d4e9f9);
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.blogScreenHeaderTitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
}

.blogScreenHeaderSubtitle {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
}