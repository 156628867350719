.completePageContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.mainContentBelowTheNavbar {
    margin-top: 45px;
    /* Adjust according to the Navbar height */
    padding: 20px;
    overflow-y: auto;
    height: calc(100vh - 45px);
    /* Adjust according to the Navbar height */
}