.investmentReadinessContent {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    /* text-align: justify !important;
    hyphens: auto !important;
    overflow-wrap: break-word;  */
    /* Move long words to the next line */
    /* word-break: keep-all;       */
    /* Prevent word breaks */
}

.removekeysmargintop {
    margin-top: -15px;
}

.companyOverviewSectionContainer {
    margin-top: 30px;
}

.investmentReadinessSectionTitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
    color: #4B4B4B;
}

.investmentReadinessCompanyName {
    font-weight: 400;
    font-size: 40px;
    line-height: 39px;
    color: #4B4B4B;
    padding-top: 5px;
}

.investmentReadinessSectionBottomLine {
    margin-top: 10px;
    border-bottom: 0.5px solid #CACACA
}

.locationDetails {
    display: flex;
    align-items: center;
    /* Vertically centers the image and text */
    margin-top: 15px;
}

.locationText,
.addressDetailsText {
    margin-left: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    display: flex;
    align-items: center;
}

.addressDetails {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.industryBussinessActivityMissionContent {
    border-left: 4px solid #47B881;
    padding-left: 10px;
    margin-top: 50px;
}

.industryContent,
.businessActivityContent {
    display: flex;
}

.industryKey,
.businessActivityKey,
.missionKeyAndValue,
.blockContentText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
}

.induistryValue,
.businessyActivityValue {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    margin-left: 5px;
}

.missionContent {
    display: flex;
    margin-top: 45px;
}

.twoByTwoSectionDisplay {
    margin-top: 50px;
}

.rowOneContent,
.rowTwoContent {
    display: flex;
}

.rowTwoContent {
    margin-top: 40px;
}

.blockOne,
.blockThree {
    width: 35%;
}

.blockTwo {
    width: 10%;
}

.titleAndIcon {
    display: flex;
    /* align-items: center; */
}

.blockTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #4B4B4B;
    margin-left: 10px;
}

.investmentReadinessSectionDivider {
    border-top: 1px solid #CACACA;
    width: 50%;
    margin-top: 10%;
}

.investmentReadinessMarketOpportunitySection {
    margin-top: 3%;
}

.investmentReadinessMarketOpportunitySectionHeader {
    display: flex;
}

.investmentReadinessMarketOpportunitySectionTitleIndex {
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
    color: #E1E1E1;
}

.investmentReadinessMarketOpportunitySectionTitleName {
    margin-left: 15px;
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
    color: #4B4B4B;
}

.investmentReadinessRecommendationsSectionTitleName {
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
    color: #4B4B4B;
}

.investmentReadinessMarketOpportunitySectionContent {
    margin-top: 7%;
    display: flex;
    flex-direction: row;
}

.nonGraphicalContentForMarketOpportunity,
.graphicalComponentForMarketOpportunity {
    width: 35%;
}

.gapSection {
    width: 10%;
}

.nonGraphicalContentForMarketOpportunity {
    border-left: 4px solid #47B881;
    padding-left: 20px;
    height: fit-content;
}

.analysisContentTitle,
.rankingContentTitle {
    display: flex;
    align-items: center;
}

.analsyisTitleName,
.rankingTitleName {
    margin-left: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24.59px;
    color: #000000;
}

.analysisContentDescription {
    margin-top: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
}

.rankingContentForMarketOpportunity {
    margin-top: 10%;
}

.tamContentSectionLine,
.samContentSectionLine {
    display: flex;
}

.rankingContentDescription {
    margin-top: 10px;
}

.tamKey,
.samKey,
.samKeyValue,
.tamKeyValue,
.averageValue,
.belowAverageValue {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.tamKey,
.samKey {
    color: #1F1F1F;
}

.samKeyValue,
.tamKeyValue {
    color: #F64C4C;
    margin-left: 5px;
}

.outer-circle {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #BDDDFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.inner-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #E5F5EC;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    margin-top: 90px;
}

.outerText {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1F1F1F;
}

.subOuterTextPartTwo {
    font-weight: 600 !important;
}

.innerText {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1F1F1F;
    text-align: center;
}

.subInnerTextPartTwo {
    font-weight: 600 !important;
    color: #4B4B4B !important;
}

.averageValue {
    color: #FE9B0E;
}

.keyInformationSectionLine {
    display: block;
    /* Make the container block-level */
    width: 100%;
    /* Let the container take full width */
}

.keyDifferentiatorsKey {
    display: inline-block;
    /* Allow the label to stay inline with the content */
    white-space: nowrap;
    /* Prevent the label from wrapping */
    margin-right: 5px;
    /* Add a small gap between label and content */
    font-weight: 700;
}

.keyDifferentiatorsValue {
    display: inline;
    /* Keep the value inline with the label */
    word-break: break-word;
    /* Break the text only if it's too long */
}

.someAnalysisImage {
    margin-top: 45px;
}

.belowAverageValue {
    color: #F64C4C;
}

.financialMetricsDetailsSection {
    margin-top: 50px;
    margin-left: 20px;
}

.financialMetricName {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1F1F1F;
}

.financialMetricValue {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1F1F1F;
}

.financialMetricsDetailsSectionOne,
.financialMetricsDetailsSectionTwo {
    display: flex;
}

.financialMetricOne,
.financialMetricTwo,
.financialMetricThree {
    width: 20%;
}

.gapBetweenFinancialMetrics {
    width: 5%;
}

.financialMetricsDetailsSectionTwo {
    margin-top: 40px;
}

.recommendationsSectionRowOne,
.recommendationsSectionRowTwo,
.recommendationsSectionRowThree,
.recommendationsSectionRowFour {
    display: flex;
}

.recommendationsSectionRowTwo,
.recommendationsSectionRowThree,
.recommendationsSectionRowFour {
    margin-top: 30px;
}

.recommendationsSectionBlockContent {
    width: 28%;
}

.gapBetweenRecommendationSectionBlocks {
    width: 8%;
}

.recommendationsSectionBlockContentTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    display: flex;
}

.recommendationsSectionBlockContentTitleName {
    margin-left: 5px;
}

.recommendationsSectionBlockContentDescription {
    margin-top: 10px;
    text-align: justify;
    /* font-weight: 500; */
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.investmentReadinessConclusionSectionContent {
    margin-top: 30px;
}

.conclusionTextForInvestmentReadiness {
    text-align: justify;
    /* font-weight: 500; */
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.contactInformationSectionDividerInvestmentReadiness {
    margin-top: 10%;
}

.contactInformationTitle {
    text-align: justify;
    /* font-weight: 500; */
    font-size: 20px;
    line-height: 32px;
    color: #4B4B4B;
}

.contactInformationDetails {
    margin-top: 10px;
    color: #1F1F1F;
}