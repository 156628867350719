.inboxContainer {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secondContainerClass {
    /* width: 90vw; */
    height: 90vh;
    /* background-color: rgba(17, 25, 40, 0.75); */
    /* backdrop-filter: blur(19px) saturate(180%); */
    /* border-radius: 12px; */
    /* border: 1px solid rgba(255, 255, 255, 0.125); */
    display: flex;
}