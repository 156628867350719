.meetingCardsDetailContainer {
    display: flex;
    flex-direction: column;
}

.headerRow {
    display: flex;
    padding: 10px 3%;
    /* Match the padding of your card columns */
    margin: 2% 3%;
    /* Ensure it aligns with the card margins */
    position: relative;
    margin-bottom: -1.5%;
}

.headerTimeColumn,
.headerTitleColumn,
.headerCompanyNameColumn,
.headerParticipantsNamesColumn {
    flex-grow: 1;
    padding: 0 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
}

/* Specific column adjustments to match the card styles */
.headerTimeColumn {
    flex-grow: 0;
    max-width: fit-content;
    margin-right: 11%;
}

.headerTitleColumn {
    flex-grow: 0;
    /* Adjust based on your preference for title width */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30%;
    max-width: 30%;
    margin-right: 2%;
}

.headerCompanyNameColumn {
    flex-grow: 0;
    width: 15%;
    max-width: 15%;
    margin-right: 1%;
}

.headerParticipantsNamesColumn {
    flex-grow: 0;
}

@media (max-width: 768px) {
    .headerRow {
        flex-direction: column;
        padding: 10px 5%;
        margin: 1% 5%;
        /* Adjust margins to align with card containers */
    }

    .headerTimeColumn,
    .headerTitleColumn,
    .headerCompanyNameColumn,
    .headerParticipantsNamesColumn {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
    }
}

@media (max-width: 480px) {
    .headerRow {
        padding: 10px 2%;
        margin: 1% 2%;
    }

    .headerTimeColumn,
    .headerTitleColumn,
    .headerCompanyNameColumn,
    .headerParticipantsNamesColumn {
        font-size: 12px;
    }
}