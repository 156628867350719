.shortAnswerWithDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Ensure it takes the full width of its parent */
}

.short-answer-text-container {
    width: 25%;
    /* Allocate 75% width to the text input container */
}

.dropdown-container-with-short-text {
    width: 15%;
    /* Allocate 20% width to the dropdown container */
    display: flex;
    flex-direction: row;
    /* Align the dropdown horizontally */
    justify-content: center;
    align-items: center;
}