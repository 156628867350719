.menu-item-selected {
  border-bottom: 4px solid #F2C41E !important;
  color: black !important;
  /* Ensures the text color is black for selected tab */
  font-weight: bold;
}

.user-profile-menu-item {
  font-weight: bold;
  color: black !important;
}

.navbar-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  line-height: 64px;
  border-bottom: none;
  box-shadow: 0 2px 8px #f0f1f2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* flex-wrap: nowrap;  */
  margin-bottom: 1rem;
}

.logo-item {
  display: flex;
  align-items: center;
  height: 64px;
}

.logo-img {
  margin-right: 140px;
  height: 40px;
}

.mailIconContainer {
  display: flex;
  align-items: center;
  height: 50px;
}

.mailIcon {
  height: 30px;
}

.menu-item {
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.menu-divider {
  padding: 0 1px;
}

.divider-line {
  height: 50px;
  width: 1px;
  background-color: black;
}

.userType {
  color: black;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.center-menu-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-shrink: 0;
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.center-menu {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  white-space: nowrap;
}

.right-menu-container {
  display: flex;
  flex-grow: 0.05;
  justify-content: center;
  flex-shrink: 0;
  white-space: nowrap;
  flex-wrap: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.right-menu-item {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  white-space: nowrap;
  flex-wrap: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

/* Styles for dropdown menu items */
.dropdown-menu {
  min-width: 180px; /* Adjust based on the width of the user menu */
}

.dropdown-menu-item {
  font-size: 1rem;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
}

.dropdown-menu-item:hover {
  background-color: #f0f1f2;
  cursor: pointer;
}

/* Override Ant Design default styles */
.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom: none !important;
}

/* Change the hover underline color */
.ant-menu-horizontal
  > .ant-menu-item:hover:not(.ant-menu-item-selected)::after {
  border-bottom: 1px solid lightgrey !important;
  /* border-bottom: none !important; */
  /* Change the color here */
}

/* Override cursor style for disabled menu items */
.ant-menu-item-disabled {
  cursor: default !important;
  pointer-events: none !important; /* Prevent clicks */
  opacity: 1 !important; /* Ensure full opacity */
  color: inherit !important; /* Ensure text color is inherited */
}
