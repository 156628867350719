.userChatContainer {
    flex: 3;
    border-left: 1px solid black;
    height: 100%;
    display: flex;
    flex-direction: column;

    

    .center {
        padding: 20px;
        flex: 1;
        /* overflow: scroll; */
        display: flex;
        flex-direction: column;
        gap: 20px;

        .message {
            max-width: 80%;
            display: flex;
            gap: 20px;

            &.own {
                align-self: flex-end;

                .texts {
                    p {
                        background-color: #D9D9D9;
                    }
                }
            }

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
              }

              .texts {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 5px;

                p {
                    padding: 20px;
                    background-color: #D9D9D9;
                    border-radius: 15px;
                }
              }
        }
    }

    .bottom {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid black;
        gap: 20px;
        flex: 1;
        margin-top: auto;

        .userSendMessage {
            display: flex;
            align-items: center;
            gap: 20px;
            /* padding: 20px; */
            flex: 1;
    
            .messageBar {
                flex: 1;
                background-color: #F7F0D4;
                display: flex;
                align-items: center;
                gap: 20px;
                border-radius: 20px;
                padding: 10px;
    
                input {
                    flex: 1;
                    background-color: #F7F0D4;
                    border: none;
                    outline: none;
                    color: black;
                    padding: 20px;
                    border-radius: 15px;
                    font-size: 16px;
              
                    &:disabled {
                      cursor: not-allowed;
                    }
                  }
    
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}